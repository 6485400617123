<template>
  <div>
    <!--      用户页面-->
    <div class="page">
<!--            <img style="position:absolute;width: 100%;height: 100%;top: 0;" class="bg-blur" :style="{'background':!canvasImg?'white':''}"-->
<!--                 :src="canvasImg">-->
      <div style="position:absolute;width: 100%;height: 100%;top: 0;background:#e8f7ff" class="bg-blur"></div>
      <div style="z-index: 99" class="content">
        <div class="top">
          <img style="width: 40px;height: 40px;border-radius: 50%" :src="wxUserData.headimgurl">
          <div class="person" style="margin-right: 5px">
            <div class="font-size-17">{{ helphandData.success_num }}人</div>
            <div class="font-size-12">助力成功人数</div>
          </div>
          <div class="person" style="margin-right: 5px">
            <div class="font-size-17">{{ helphandData.diff_num }}人</div>
            <div class="font-size-12">待助力人数</div>
          </div>
          <div class="submit-btn" style="background: #999999" v-if="helphandData.diff_num != 0" @click="noreceiveReward">领取奖品</div>
          <div class="submit-btn" @click="receiveReward" v-else>
            领取奖品
          </div>
        </div>
        <div class="center">
          <div class="code-border">
            <div class="qr-code" ref="qrCode"></div>
            <div class="show_poster" v-show="!canvasImg">
            </div>
            <img :src="canvasImg" alt="" v-show="canvasImg" style="width: 100%;height: auto">
            <div style="font-size: 11px;font-weight: 500;margin: 16px auto 0;text-align: center;color: #d36942">
              请点击长按海报保存,发给好友或朋友圈
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="title">
            <span class="text">我的助力好友</span>
          </div>
          <div class="content-list" v-if="helphandData.invite_friends && helphandData.invite_friends.length > 0">
            <div class="row" v-for="item in helphandData.invite_friends" :key="index">
              <div class="left">
                <img :src="item.avatar" class="avatar">
                <div class="name">{{ item.nickname }}</div>
              </div>
              <div class="right">
                <div class="user-info">
                  <div class="time">{{ item.createdAt }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-list" style="text-align: center" v-else>
            <img style="width: 62px;height: 62px;margin-top: 14px;margin-bottom: 22px"
                 src="../../static/images/none-user.png">
            <div class="text">暂无好友助力，赶快转发海报给好友吧~~</div>
          </div>
        </div>
        <input type="text" ref="copyInput" style="position: fixed; opacity: 0">
      </div>
    </div>

    <canvas ref="canvas" width="720" height="1280">
      您的浏览器不支持canvas
    </canvas>

    <!--完成任务-->
    <div class="mask" v-show="prizeShow">
      <div class="content">
        <div class="title">恭喜你完成任务</div>
        <img :src="receiveCode" alt="" class="gift-image">
        <div class="tip">长按保存二维码添加客服</div>
        <a-icon type="close-circle" @click="closeBtn" class="closeIcon"/>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import {inviteFriendsApi, openUserInfoApi, posterApi, receiveApi} from '@/api/roomFission'

export default {
  name: "poster",
  data() {
    return {
      dataList: {},
      userInfo: {},
      bgHeight: 'auto',
      base64Img: '',
      //    微信授权信息
      wxUserData: {},
      //  群聊信息
      roomNews: {},
      //  用户海报信息
      posterNews: {},
      canvasImg: '',
      parentUnionId: '',
      fissionId: 0,
      wxUserId: '',

      prizeShow: false,
      helphandData: {},
      receiveCode: '',
    }
  },

  created() {
    this.canvasImg = ''
    this.fissionId = this.$route.query.id;
    // 需要兼容老的query参数
    if (this.$route.query.parentUnionId !== undefined) {
      this.parentUnionId = this.$route.query.parentUnionId;
    } else if (this.$route.query.parent_union_id !== undefined) {
      this.parentUnionId = this.$route.query.parent_union_id;
    }

    if (this.$route.query.wxUserId !== undefined) {
      this.wxUserId = this.$route.query.wxUserId;
    } else if (this.$route.query.wx_user_id !== undefined) {
      this.wxUserId = this.$route.query.wx_user_id;
    }
    this.getOpenUserInfo();
  },
  methods: {
    closeBtn() {
      this.prizeShow = false
    },
    //获取助力信息
    getHelpData(params) {
      inviteFriendsApi(params).then((res) => {
        this.helphandData = res.data
      })
    },
    //领取奖励
    receiveReward() {
      receiveApi({
        fissionId: this.fissionId,
        unionId: this.wxUserData.unionid
      }).then((res) => {
        this.prizeShow = true
        this.receiveCode = res.data.qrCode
      })
    },
    noreceiveReward(){
      this.$message.error('请将海报分享给朋友完成助力~')
    },
    getOpenUserInfo() {
      let that = this;
      openUserInfoApi({
        id: that.fissionId
      }).then((res) => {
        if (res.data.openid === undefined) {
          let redirectUrl = '/auth/roomFission?id=' + that.fissionId + '&target=' + encodeURIComponent(that.url);
          that.$redirectAuth(redirectUrl);
        }
        this.wxUserData = res.data;

        this.getPosterData();
      });
    },
    //  获取海报数据
    getPosterData() {
      let params = {
        fissionId: this.fissionId,
        parentUnionId: this.parentUnionId,
        wxUserId: this.wxUserId,
        unionId: this.wxUserData.unionid,
        openId: this.wxUserData.openid,
        nickname: this.wxUserData.nickname,
        avatar: this.wxUserData.headimgurl,
        type: 2
      }
      posterApi(params).then((res) => {
        document.title = res.data.fission.activeName ?? "群裂变"
        if (res.data.type == 0) {
          this.$message.info('活动已结束');
          return false
        }

        this.posterNews = res.data.poster
        this.initQRCode()
        this.getHelpData({
          fissionId: this.fissionId,
          unionId: this.wxUserData.unionid
        });
      })
    },
    initQRCode() {
      this.$refs.qrCode.innerHTML = ''
      new QRCode(this.$refs.qrCode, {
        text: this.posterNews.qrCodeUrl,
        width: 85,
        height: 85,
      })

      this.generatePoster();
    },
    async generatePoster() {
      const ctx = this.$refs.canvas.getContext('2d')
      const w = 720
      const h = 1280

      const bg = await this.getImg(this.posterNews.coverPic)
      const qr = await this.getQrCodeImg()

      ctx.drawImage(bg, 0, 0, w, h)
      //ctx.drawImage(qr, 550, 1100, 150, 150)
      if (this.posterNews.qrcodeX) {
        // ctx.drawImage(qr, this.posterNews.qrcodeX * 3.37, this.posterNews.qrcodeY* 3.23,  this.posterNews.qrcodeW * 3,  this.posterNews.qrcodeH * 3)
        ctx.drawImage(qr, this.posterNews.qrcodeX * 3.24, this.posterNews.qrcodeY * 3.23, this.posterNews.qrcodeW * 3.24, this.posterNews.qrcodeH * 3.24)
      } else {
        ctx.drawImage(qr, 550, 1100, 150, 150)
      }


      if (this.wxUserData.nickname && this.posterNews.nicknameShow == 1) {
        ctx.font = '30px Verdana'
        ctx.fillStyle = this.posterNews.nicknameColor ?? '#fff'
        // ctx.fillText(this.wxUserData.nickname, 115, 74)
        if (this.posterNews.nicknameX) {
          // ctx.fillText(this.wxUserData.nickname, this.posterNews.nicknameX * 3.37, this.posterNews.nicknameY* 3.23 + (this.posterNews.avatarW * 3.23 / 2))
          ctx.fillText(this.wxUserData.nickname, this.posterNews.nicknameX * 3.24, this.posterNews.nicknameY * 3.23 + (this.posterNews.avatarW * 3.23 / 2))
        } else {
          ctx.fillText(this.wxUserData.nickname, 115, 74)
        }

      }

      if (this.wxUserData.headimgurl && this.posterNews.avatarShow == 1) {
        const avatar = await this.getImg(this.wxUserData.headimgurl)
        // ctx.drawImage(avatar, 30, 30, 70, 70)
        if (this.posterNews.avatarX) {
          // ctx.drawImage(avatar, this.posterNews.avatarX * 3.37, this.posterNews.avatarY* 3.23,  this.posterNews.avatarW * 3.23,  this.posterNews.avatarH* 3.23)
          ctx.drawImage(avatar, this.posterNews.avatarX * 3.24, this.posterNews.avatarY * 3.23, this.posterNews.avatarW * 3.23, this.posterNews.avatarH * 3.23)
        } else {
          ctx.drawImage(avatar, 30, 30, 70, 70)
        }
      }

      this.canvasImg = this.$refs.canvas.toDataURL('image/png')
    },
    getQrCodeImg() {
      return new Promise(resolve => {
        const node = document.createElement('img')
        node.setAttribute('src', this.$refs.qrCode.querySelector('canvas').toDataURL('image/png'))

        node.onload = () => {
          resolve(node)
        }
      })
    },
    getImg(url) {
      url += '?rand=' + Math.random()

      return new Promise(resolve => {
        const img = new Image()

        img.src = url
        img.crossOrigin = "Anonymous";

        img.onload = () => {
          resolve(img)
        }

        img.onerror = err => {
          console.log('图片加载失败');
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 720px;
  height: 1280px;
  z-index: 10000;
  display: none;
}

.bg-blur {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  //-webkit-filter: blur(15px);
  //-moz-filter: blur(15px);
  //-o-filter: blur(15px);
  //-ms-filter: blur(15px);
  //filter: blur(15px);
}

.page {
  width: 100vw;
  height: 100vh;
  //height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;

  .content {
    padding: 12px;
  }

  .top {
    width: 100%;
    padding: 7px 9px;
    background-color: white;
    //border: 1px solid #0F8AFF;
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .person {
      width: auto;
      height: 100%;
      padding: 0 13px;
      text-align: center;
      border-right: 1px solid #DDDDDD;
    }

    .font-size-17 {
      font-size: 17px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
    }

    .font-size-12 {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #333333;
    }

    .submit-btn {
      width: 91px;
      height: 28px;
      line-height: 28px;
      text-align: center;
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      background: linear-gradient(45deg, #FF7C34, #FFA200);
      border-radius: 14px;
    }
  }


  .center {
    padding: 0 47px;
    height: auto;

    .reply {
      padding-top: 16px;

      .content {
        padding: 16px 20px;
        border-radius: 16px;
        background-color: rgb(253, 253, 229);

        .title {
          display: flex;
          justify-content: space-between;

          .tip {
            color: saddlebrown;
            font-size: 15px;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 7px;
              background-color: #b97e1229;
            }
          }

          .copy {

          }
        }

        .text {

        }
      }
    }

    .tips {
      padding: 20px 0;

      .tip {
        display: flex;
        justify-content: center;
        margin-bottom: 4px;

        .text {
          position: relative;
          color: white;
          z-index: 0;

          &::before {
            position: absolute;
            content: '';
            bottom: 0;
            z-index: -1;

            left: ((100% - 90%) / 2);

            height: 10px;
            width: 90%;
            border-radius: 100px;
            background-color: rgba(255, 255, 255, 0.15);
          }
        }

        .long-text {
          &::before {
            left: ((100% - 110%) / 2);
            width: 110%;
          }
        }
      }
    }

    .code-border {
      width: 100%;
      padding: 20px 22px;
      background-color: white;
      border-radius: 14px;
    }
  }

  .bottom {
    //background-image: url("../../static/images/poster_center.png");
    //background-repeat: no-repeat;
    //background-size: 100% 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 30px 12px 7px;
    position: relative;
    margin-top: 26px;
    min-height: 184px;
    display: flex;
    flex-direction: column;

    .title {
      width: 100%;
      top: -13px;
      left: 0;
      position: absolute;
      display: flex;
      justify-content: center;

      .text {
        color: white;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        padding: 7px 57px 5px 50px;
        background-image: url("../../static/images/poster_top.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }

    .content-list {
      height: 0;
      flex-grow: 1;
      overflow-y: auto;

      .text {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
      }

      .row {
        padding: 8px 10px;
        height: auto;
        background: linear-gradient(0deg, #F4FAFE 0%, #F8FCFE 100%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .left {
          display: flex;
          align-items: center;

          .avatar {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 9px;
          }

          .name {
            color: #333333;
            font-size: 12px;
            font-weight: 500;
          }
        }

        .right {
          .user-info {
            .time {
              color: #999999;
              font-size: 12px;
              font-weight: 500;
            }
          }

          .tips {
            .tip {
              font-size: 12px;
              color: #B7EB8F;
            }
          }
        }
      }
    }
  }

}

.show_poster {
  width: 100%;
  position: relative;
  //background: skyblue;
}

.cover_pic {
  width: 100%;
  height: 100%;
}

//.show_user_news {
//  position: absolute;
//  width: 100%;
//  height: 100%;
//  top: 0;
//}

.show_user_data {
  margin-left: 15px;
  margin-top: 15px;
  display: flex;
  position: absolute;
  top: 0;
}

.qr-code {
  position: absolute;
  bottom: 10000px;
  right: 10px;
}

.user_img {
  width: 35px;
  height: 35px;
  border-radius: 5px;
}

.user_name {
  margin-left: 10px;
  margin-top: 6px;
  font-size: 15px;
  font-weight: bold;
}

.user_qrCode {
  width: 50px;
  height: 50px;
}

//abc
.pageCode {
  width: 100vw;
  height: 100vh;
  background-color: #bfddff;
}

.qr_room_img {
  width: 217px;
  height: 217px;
  //height: auto;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 188;
  background-color: rgba(0, 0, 0, 0.64);
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    width: 84%;
    padding: 20px 0 30px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .title {
      font-size: 16px;
      color: black;
    }

    .closeIcon {
      position: absolute;
      font-size: 20px;
      right: 10px;
      top: 8px;
    }

    .gift-image {
      margin-top: 5px;
      width: 200px;
      height: 200px;
    }

    .tip {
      margin-top: 10px;
      margin-bottom: -12px;
    }

    .get-gift {
      background-color: #ff5636;
      width: 50%;
      height: 32px;
      display: flex;
      color: white;
      border-radius: 32px;
      justify-content: center;
      align-items: center;

    }
  }
}

.qc_code {
  display: flex;
  justify-content: center;

  .code-box {
    margin-top: 50px;
    width: 90vw;
    height: auto;
    background-color: #ffffff;
    padding-bottom: 20px;
  }

  .top {
    //display: flex;
    //align-items: center;
    padding: 28px 28px 20px 28px;

    .left {
      margin-right: 15px;
    }
  }

  .shop_name {
    font-size: 17px;
    color: #222;
    font-weight: bold;
    text-align: center;
  }

  .shop_describe {
    color: #818181;
  }

  .shop_guide {
    margin-top: 17px;
    text-align: center;
    color: #818181;
  }
}
</style>
